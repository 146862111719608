.Card {
  background-color: white;
  height: 25rem;
  width:100%;
  box-shadow: 0 1.5rem 4rem rgba(white, 0.15);
  display: grid;
  border-radius: 0.5rem;
}

.Card-heading {
  justify-self: center;
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: .7rem;
  background-color: green;
  width: 100%;
  height: 4.5rem;
}

.Card-heading h3{
    text-align: center;
    line-height: 4.5rem;
}

.Card-content {
  justify-self: center;
  font-size: 1.5rem;
  text-align: center;
  line-height: 2rem;
  margin: 1rem;
}
