.PastorCorner{
    display: grid;
    row-gap: 2rem;
}
.PastorCorner-title{
    font-size: 2.5rem;
}

.PastorCorner-callus{
    text-align: center;
}

.PastorCorner-summary span {
    font-family: "Waiting for the sunrise";
    display: block;
    font-size: 2rem;
    font-weight: 600;
    font-style: normal;
  }