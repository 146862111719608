.Navigation {
  background-color: #b3d9e6;
  height: 7rem;
}

.Navigation-header {
  display: none;
}

.Navigation-header h2 {
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: 1rem;
  color: #fff;
  text-transform: uppercase;
}

.Navigation-links {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.Navigation-links li {
  margin-right: 2rem;
  list-style: none;
}

.Navigation-links a {
  /* font-size: 1.2rem; */
  text-transform: capitalize;
  color: hsl(209, 61%, 16%);
  /* font-weight: 400; */
  letter-spacing: 0.2rem;
  transition: all 0.3s linear;
  padding: 0 1rem;
  line-height: 7rem;
  text-decoration: none;
}

.Navigation-links a:hover {
  color: #2caeba;
  box-shadow: 0px 2px #2caeba;
}


@media screen and (max-width: 768px) {
  .Navigation {
    background-color: #b3d9e6;
  }
  .Navigation-links {
    display: none;
  }

  .Navigation-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;
    line-height: 7rem;
  }

  .toggle-btn {
    font-size: 2.5rem;
    background: transparent;
    border-color: transparent;
    cursor: pointer;
    transition: all 0.3s linear;
  }
}
