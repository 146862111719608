.About {
 display: grid;
 row-gap: 2rem;
}

.About-summary {
  text-align: center;
  font-size: 1.8rem;
  font-style: italic;
}

.About-summary span {
  font-family: "Waiting for the sunrise";
  display: block;
  font-size: 2rem;
  font-weight: 600;
  font-style: normal;
}
