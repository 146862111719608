@media screen and (max-width: 768px) {
  .Sidebar {
    background-color: #b3d9e6;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    display: grid;
    place-items: center;
    opacity: 0;
    transition: all 0.3s linear;
    transform: translateX(-100%);
  }

  .show-sidebar {
    opacity: 1;
    transform: translateX(0);
  }

  .Sidebar-links li {
    opacity: 0;
    list-style: none;
  }
  .Sidebar-links li a {
    font-weight: 400;
    display: block;
    text-align: center;
    text-transform: capitalize;
    color:#fff;
    letter-spacing: 1rem;
    margin-bottom: 3rem;
    font-size: 2.5rem;
    transition: all 0.3s linear;
    border-radius: .25rem;
    text-decoration: none;
  }
  .Sidebar-links li a:hover {
    background:hsl(185, 94%, 87%);
    color:#2caeba;
    
  }

  .close-btn {
    position: absolute;
    right: 1.55%;
    top: 1.70%;
    font-size: 3.5rem;
    background: transparent;
    border-color: transparent;
    /* color: orange; */
    cursor: pointer;
  }


  /* //Sidebar  */

  .Sidebar-links li {
    animation: slideRight 0.5s ease-in-out 0.3s forwards;
  }
  .Sidebar-links li:nth-of-type(1) {
    animation-delay: 0.25s;
  }
  .Sidebar-links li:nth-of-type(2) {
    animation-delay: 0.5s;
  }
  .Sidebar-links li:nth-of-type(3) {
    animation-delay: 0.75s;
  }
  .Sidebar-links li:nth-of-type(4) {
    animation-delay: 1s;
  }
  .Sidebar-links li:nth-of-type(5) {
    animation-delay: 1.25s;
  }
  @keyframes slideRight {
    0% {
      transform: translateX(-200px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  .Sidebar-icons li {
    opacity: 0;
    animation: slideUp 0.5s ease-in-out 0.3s forwards;
  }
  .Sidebar-icons li:nth-of-type(1) {
    animation-delay: 0.25s;
  }
  .Sidebar-icons li:nth-of-type(2) {
    animation-delay: 0.5s;
  }
  .Sidebar-icons li:nth-of-type(3) {
    animation-delay: 0.75s;
  }
  .Sidebar-icons li:nth-of-type(4) {
    animation-delay: 1s;
  }
  .Sidebar-icons li:nth-of-type(5) {
    animation-delay: 1.25s;
  }
  @keyframes slideUp {
    0% {
      transform: translateY(200px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
}
