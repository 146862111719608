.Scriptures{
  margin-bottom: 2rem;
}

.Scriptures-verse{
  font-family: 'Waiting for the sunrise';
  text-align: center;
  color:#ffff;
  font-size:3rem;
  font-weight: 300;
  text-shadow: 2px black;
}

.Scriptures-text{
  font-family: 'Waiting for the sunrise';
  width: 70%;
  text-align: center;
  color:#ffff;
  font-size:2.5rem;
  font-weight: 300;
  text-shadow: 2px black;
  margin: 0 auto;
  /* padding-bottom: 10vh; */
}