@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;700&family=Shadows+Into+Light&family=Waiting+for+the+Sunrise&display=swap);
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

body {
  font-family: "Raleway", "Open Sans", sans-serif, -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px;
}

@media screen and (max-width: 600px) {
  body{
    font-size: 62.5%; 
     /* 1rem => 10px */
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.About {
 display: grid;
 grid-row-gap: 2rem;
 row-gap: 2rem;
}

.About-summary {
  text-align: center;
  font-size: 1.8rem;
  font-style: italic;
}

.About-summary span {
  font-family: "Waiting for the sunrise";
  display: block;
  font-size: 2rem;
  font-weight: 600;
  font-style: normal;
}

.PageContent {
  background-color: #f0f7fa;
  color: black;
  display: grid;
  grid-template-rows: 7rem 70vh repeat(2, -webkit-min-content);
  grid-template-rows: 7rem 70vh repeat(2, min-content);
  grid-template-columns:
    [full-start] minmax(6rem, 2fr) [center-start]
    repeat(8, minmax(-webkit-min-content, 14rem)) [center-end] minmax(6rem, 2fr) [full-end];
  grid-template-columns:
    [full-start] minmax(6rem, 2fr) [center-start]
    repeat(8, minmax(min-content, 14rem)) [center-end] minmax(6rem, 2fr) [full-end];
}

.PageContent nav {
  grid-column: full-start / full-end;
}

.PageContent header {
  grid-column: full-start / full-end;
}

.PageContent section {
  grid-column: center-start / center-end;
}

.PageContent footer {
  grid-column: full-start / full-end;
}

.PageContent-content {
  margin-bottom: 5rem;
}

@media screen and (max-width: 768px) {
  .PageContent header {
    display: none;
  }

  .PageContent section {
    margin-top: 2rem;
  }

  .PageContent footer {
    margin-top: 45rem;
  }
}

.Navigation {
  background-color: #b3d9e6;
  height: 7rem;
}

.Navigation-header {
  display: none;
}

.Navigation-header h2 {
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: 1rem;
  color: #fff;
  text-transform: uppercase;
}

.Navigation-links {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.Navigation-links li {
  margin-right: 2rem;
  list-style: none;
}

.Navigation-links a {
  /* font-size: 1.2rem; */
  text-transform: capitalize;
  color: hsl(209, 61%, 16%);
  /* font-weight: 400; */
  letter-spacing: 0.2rem;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  padding: 0 1rem;
  line-height: 7rem;
  text-decoration: none;
}

.Navigation-links a:hover {
  color: #2caeba;
  box-shadow: 0px 2px #2caeba;
}


@media screen and (max-width: 768px) {
  .Navigation {
    background-color: #b3d9e6;
  }
  .Navigation-links {
    display: none;
  }

  .Navigation-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;
    line-height: 7rem;
  }

  .toggle-btn {
    font-size: 2.5rem;
    background: transparent;
    border-color: transparent;
    cursor: pointer;
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear;
  }
}

.Banner{
    height: 65vh;
    width: 100vw;
    background-size: cover;
    background-position: center;
    color:white;
}

.Banner-heading{
    font-size: 7rem;
    padding:5rem 0 0 5rem;
}

/* .Banner{
    display: grid;
    grid-template-columns: 70% 30%;

} */
.Footer{
    background-color: #B3D9E6;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
    grid-row-gap: 3rem;
    row-gap: 3rem;
    grid-column-gap: 3rem;
    -webkit-column-gap: 3rem;
       -moz-column-gap: 3rem;
            column-gap: 3rem;
    padding: 5rem;
}

.Footer-details{
    display: grid;
    grid-row-gap: 2rem;
    row-gap: 2rem;
}


.Footer-image {
    align-self: center;
    justify-self: center;
}
/* 
.Footer-image h3{
    font-size: 3rem;
    color:green;
    font-weight: 700;
} */

.Footer-image img{
    width: 200px;
}
@media screen and (max-width: 768px) {
  .Sidebar {
    background-color: #b3d9e6;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    display: grid;
    place-items: center;
    opacity: 0;
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }

  .show-sidebar {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }

  .Sidebar-links li {
    opacity: 0;
    list-style: none;
  }
  .Sidebar-links li a {
    font-weight: 400;
    display: block;
    text-align: center;
    text-transform: capitalize;
    color:#fff;
    letter-spacing: 1rem;
    margin-bottom: 3rem;
    font-size: 2.5rem;
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear;
    border-radius: .25rem;
    text-decoration: none;
  }
  .Sidebar-links li a:hover {
    background:hsl(185, 94%, 87%);
    color:#2caeba;
    
  }

  .close-btn {
    position: absolute;
    right: 1.55%;
    top: 1.70%;
    font-size: 3.5rem;
    background: transparent;
    border-color: transparent;
    /* color: orange; */
    cursor: pointer;
  }


  /* //Sidebar  */

  .Sidebar-links li {
    -webkit-animation: slideRight 0.5s ease-in-out 0.3s forwards;
            animation: slideRight 0.5s ease-in-out 0.3s forwards;
  }
  .Sidebar-links li:nth-of-type(1) {
    -webkit-animation-delay: 0.25s;
            animation-delay: 0.25s;
  }
  .Sidebar-links li:nth-of-type(2) {
    -webkit-animation-delay: 0.5s;
            animation-delay: 0.5s;
  }
  .Sidebar-links li:nth-of-type(3) {
    -webkit-animation-delay: 0.75s;
            animation-delay: 0.75s;
  }
  .Sidebar-links li:nth-of-type(4) {
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
  }
  .Sidebar-links li:nth-of-type(5) {
    -webkit-animation-delay: 1.25s;
            animation-delay: 1.25s;
  }
  @-webkit-keyframes slideRight {
    0% {
      -webkit-transform: translateX(-200px);
              transform: translateX(-200px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes slideRight {
    0% {
      -webkit-transform: translateX(-200px);
              transform: translateX(-200px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }
  .Sidebar-icons li {
    opacity: 0;
    -webkit-animation: slideUp 0.5s ease-in-out 0.3s forwards;
            animation: slideUp 0.5s ease-in-out 0.3s forwards;
  }
  .Sidebar-icons li:nth-of-type(1) {
    -webkit-animation-delay: 0.25s;
            animation-delay: 0.25s;
  }
  .Sidebar-icons li:nth-of-type(2) {
    -webkit-animation-delay: 0.5s;
            animation-delay: 0.5s;
  }
  .Sidebar-icons li:nth-of-type(3) {
    -webkit-animation-delay: 0.75s;
            animation-delay: 0.75s;
  }
  .Sidebar-icons li:nth-of-type(4) {
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
  }
  .Sidebar-icons li:nth-of-type(5) {
    -webkit-animation-delay: 1.25s;
            animation-delay: 1.25s;
  }
  @-webkit-keyframes slideUp {
    0% {
      -webkit-transform: translateY(200px);
              transform: translateY(200px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes slideUp {
    0% {
      -webkit-transform: translateY(200px);
              transform: translateY(200px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }
}

.PastorCorner{
    display: grid;
    grid-row-gap: 2rem;
    row-gap: 2rem;
}
.PastorCorner-title{
    font-size: 2.5rem;
}

.PastorCorner-callus{
    text-align: center;
}

.PastorCorner-summary span {
    font-family: "Waiting for the sunrise";
    display: block;
    font-size: 2rem;
    font-weight: 600;
    font-style: normal;
  }
body {
  background-color: #000;  
}

.Landing {
  z-index: 1;
  position: relative;
  padding-top: 3rem;
  display: grid;
  /* grid-template-rows: repeat(4, max-content); */
  grid-template-columns:
    minmax(6rem, 1fr) [center-start]
    repeat(
      8,
      [col-start] minmax(-webkit-min-content, 14rem) [col-end]
    ) [center-end] minmax(6rem, 1fr);
  grid-template-columns:
    minmax(6rem, 1fr) [center-start]
    repeat(
      8,
      [col-start] minmax(min-content, 14rem) [col-end]
    ) [center-end] minmax(6rem, 1fr);
  padding-top: 5vh;
}

.Landing-content {
  display: grid;
  grid-column: center-start / center-end;
  grid-row-gap: 3rem;
  row-gap: 3rem;
}

.Landing img {
  width: 10rem;
  height: 10rem;
  justify-self: center;
}

.Landing-truevine {
  font-family: "shadows into light";
  /* margin: 5rem 0; */
  font-size: 8.5rem;
  font-weight: 400;
  color: #6be585;
  justify-self: center;
  text-align: center;
}

.Landing-button {
  font-size: 1.2rem;
  color: #fff;
  background-color: #6be585;
  padding: 1rem;
  border-radius: 2px;
}

.Buttons-links {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  grid-column-gap: 5rem;
  -webkit-column-gap: 5rem;
     -moz-column-gap: 5rem;
          column-gap: 5rem;
  margin-top: 2rem;
  grid-row-gap: 5rem;
  row-gap: 5rem;
}

.LandingPage-card{
  color:gray;
}

.LandingPage-card-button{
  margin-top:1.2rem;
  width:13rem;
  height:4rem;
  border:0;
}

.LandingPage-card-nos li{
  list-style: none;
}





.item {
  width: 20rem;
  height: 5rem;
  text-align: center;
  line-height: 5rem;
  font-size: 2rem;
  text-transform: uppercase;
  color: azure;
}

.slideshow {
  position: fixed;
  width: 100%;
  height: 100%;
  /* height: 90vh; */
  top: 0;
  left: 0;
  z-index: 0;
  list-style: none;
  margin: 0;
  padding: 0;
}

.slideshow li {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  opacity: 0;
  z-index: 0;
  -webkit-animation: animateImage 50s linear infinite;
          animation: animateImage 50s linear infinite;
}

.slideshow li:nth-child(1) {
  background-image: url(/static/media/image1.ce629efa.jpg);
}

.slideshow li:nth-child(2) {
  background-image: url(/static/media/image2.6d6e1331.jpg);
  -webkit-animation-delay: 10s;
          animation-delay: 10s;
}

.slideshow li:nth-child(3) {
  background-image: url(/static/media/image3.d86e27aa.jpg);
  -webkit-animation-delay: 20s;
          animation-delay: 20s;
}

.slideshow li:nth-child(4) {
  background-image: url(/static/media/image1.ce629efa.jpg);
  -webkit-animation-delay: 30s;
          animation-delay: 30s;
}

.slideshow li:nth-child(5) {
  background-image: url(/static/media/image2.6d6e1331.jpg);
  -webkit-animation-delay: 40s;
          animation-delay: 40s;
}

.bg-rose {
  background-color: #a76e81;
  cursor: pointer;
  color: #fff;
}
.bg-indigo {
  background-color: #264653;
}
.bg-green {
  background-color: #00a896;
}
@-webkit-keyframes animateImage {
  0% {
    opacity: 0;
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }

  10% {
    opacity: 1;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }

  20% {
    opacity: 1;
  }

  30% {
    opacity: 0;
  }
}
@keyframes animateImage {
  0% {
    opacity: 0;
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }

  10% {
    opacity: 1;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }

  20% {
    opacity: 1;
  }

  30% {
    opacity: 0;
  }
}

.Scriptures{
  margin-bottom: 2rem;
}

.Scriptures-verse{
  font-family: 'Waiting for the sunrise';
  text-align: center;
  color:#ffff;
  font-size:3rem;
  font-weight: 300;
  text-shadow: 2px black;
}

.Scriptures-text{
  font-family: 'Waiting for the sunrise';
  width: 70%;
  text-align: center;
  color:#ffff;
  font-size:2.5rem;
  font-weight: 300;
  text-shadow: 2px black;
  margin: 0 auto;
  /* padding-bottom: 10vh; */
}
.Card {
  background-color: white;
  height: 25rem;
  width:100%;
  box-shadow: 0 1.5rem 4rem rgba(white, 0.15);
  display: grid;
  border-radius: 0.5rem;
}

.Card-heading {
  justify-self: center;
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: .7rem;
  background-color: green;
  width: 100%;
  height: 4.5rem;
}

.Card-heading h3{
    text-align: center;
    line-height: 4.5rem;
}

.Card-content {
  justify-self: center;
  font-size: 1.5rem;
  text-align: center;
  line-height: 2rem;
  margin: 1rem;
}

.Departments{
    display: grid;
    grid-row-gap: 3rem;
    row-gap: 3rem;
}

.Departments-heading{
    font-size: 2.5rem;
}
html{
  box-sizing: border-box;
  font-size: 62.5%;
}

.App {
  font-family: "Raleway", Arial, Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  color: white;
  line-height: 2em;
}

