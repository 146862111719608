body {
  background-color: #000;  
}

.Landing {
  z-index: 1;
  position: relative;
  padding-top: 3rem;
  display: grid;
  /* grid-template-rows: repeat(4, max-content); */
  grid-template-columns:
    minmax(6rem, 1fr) [center-start]
    repeat(
      8,
      [col-start] minmax(min-content, 14rem) [col-end]
    ) [center-end] minmax(6rem, 1fr);
  padding-top: 5vh;
}

.Landing-content {
  display: grid;
  grid-column: center-start / center-end;
  row-gap: 3rem;
}

.Landing img {
  width: 10rem;
  height: 10rem;
  justify-self: center;
}

.Landing-truevine {
  font-family: "shadows into light";
  /* margin: 5rem 0; */
  font-size: 8.5rem;
  font-weight: 400;
  color: #6be585;
  justify-self: center;
  text-align: center;
}

.Landing-button {
  font-size: 1.2rem;
  color: #fff;
  background-color: #6be585;
  padding: 1rem;
  border-radius: 2px;
}

.Buttons-links {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  column-gap: 5rem;
  margin-top: 2rem;
  row-gap: 5rem;
}

.LandingPage-card{
  color:gray;
}

.LandingPage-card-button{
  margin-top:1.2rem;
  width:13rem;
  height:4rem;
  border:0;
}

.LandingPage-card-nos li{
  list-style: none;
}





.item {
  width: 20rem;
  height: 5rem;
  text-align: center;
  line-height: 5rem;
  font-size: 2rem;
  text-transform: uppercase;
  color: azure;
}

.slideshow {
  position: fixed;
  width: 100%;
  height: 100%;
  /* height: 90vh; */
  top: 0;
  left: 0;
  z-index: 0;
  list-style: none;
  margin: 0;
  padding: 0;
}

.slideshow li {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  opacity: 0;
  z-index: 0;
  animation: animateImage 50s linear infinite;
}

.slideshow li:nth-child(1) {
  background-image: url(../images/image1.jpg);
}

.slideshow li:nth-child(2) {
  background-image: url(../images/image2.jpg);
  animation-delay: 10s;
}

.slideshow li:nth-child(3) {
  background-image: url(../images/image3.jpg);
  animation-delay: 20s;
}

.slideshow li:nth-child(4) {
  background-image: url(../images/image1.jpg);
  animation-delay: 30s;
}

.slideshow li:nth-child(5) {
  background-image: url(../images/image2.jpg);
  animation-delay: 40s;
}

.bg-rose {
  background-color: #a76e81;
  cursor: pointer;
  color: #fff;
}
.bg-indigo {
  background-color: #264653;
}
.bg-green {
  background-color: #00a896;
}
@keyframes animateImage {
  0% {
    opacity: 0;
    animation-timing-function: ease-in;
  }

  10% {
    opacity: 1;
    animation-timing-function: ease-out;
  }

  20% {
    opacity: 1;
  }

  30% {
    opacity: 0;
  }
}
