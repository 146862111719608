.Banner{
    height: 65vh;
    width: 100vw;
    background-size: cover;
    background-position: center;
    color:white;
}

.Banner-heading{
    font-size: 7rem;
    padding:5rem 0 0 5rem;
}

/* .Banner{
    display: grid;
    grid-template-columns: 70% 30%;

} */