.Footer{
    background-color: #B3D9E6;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
    row-gap: 3rem;
    column-gap: 3rem;
    padding: 5rem;
}

.Footer-details{
    display: grid;
    row-gap: 2rem;
}


.Footer-image {
    align-self: center;
    justify-self: center;
}
/* 
.Footer-image h3{
    font-size: 3rem;
    color:green;
    font-weight: 700;
} */

.Footer-image img{
    width: 200px;
}