html{
  box-sizing: border-box;
  font-size: 62.5%;
}

.App {
  font-family: "Raleway", Arial, Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  color: white;
  line-height: 2em;
}
