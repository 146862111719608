.PageContent {
  background-color: #f0f7fa;
  color: black;
  display: grid;
  grid-template-rows: 7rem 70vh repeat(2, min-content);
  grid-template-columns:
    [full-start] minmax(6rem, 2fr) [center-start]
    repeat(8, minmax(min-content, 14rem)) [center-end] minmax(6rem, 2fr) [full-end];
}

.PageContent nav {
  grid-column: full-start / full-end;
}

.PageContent header {
  grid-column: full-start / full-end;
}

.PageContent section {
  grid-column: center-start / center-end;
}

.PageContent footer {
  grid-column: full-start / full-end;
}

.PageContent-content {
  margin-bottom: 5rem;
}

@media screen and (max-width: 768px) {
  .PageContent header {
    display: none;
  }

  .PageContent section {
    margin-top: 2rem;
  }

  .PageContent footer {
    margin-top: 45rem;
  }
}
