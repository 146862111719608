@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;700&family=Shadows+Into+Light&family=Waiting+for+the+Sunrise&display=swap");
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

body {
  font-family: "Raleway", "Open Sans", sans-serif, -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px;
}

@media screen and (max-width: 600px) {
  body{
    font-size: 62.5%; 
     /* 1rem => 10px */
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
